<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <polygon
        style="fill:#FED159;"
        points="423.151,512 350.709,512 297.6,425.625 276.555,391.394 348.984,391.394 370.03,425.625 	
		"
      />
      <polygon
        style="fill:#FED159;"
        points="235.44,391.394 214.395,425.625 161.286,512 88.857,512 141.952,425.625 162.998,391.394 	
		"
      />
    </g>
    <g>
      <polygon
        style="fill:#F6C454;"
        points="162.998,391.394 235.44,391.394 214.395,425.625 141.952,425.625 	"
      />
      <polygon
        style="fill:#F6C454;"
        points="370.03,425.625 297.6,425.625 276.555,391.394 348.984,391.394 	"
      />
    </g>
    <g>
      <rect
        x="21.756"
        y="83.89"
        style="fill:#F2F2F2;"
        width="468.503"
        height="309.407"
      />
      <rect
        x="21.756"
        y="61.862"
        style="fill:#F2F2F2;"
        width="468.503"
        height="54.356"
      />
    </g>
    <polygon
      style="fill:#FED159;"
      points="103.272,83.895 511.449,83.895 511.449,0 0.551,0 0.551,83.895 72.846,83.895 "
    />
    <rect
      x="76.993"
      y="236.102"
      style="fill:#65B4BB;"
      width="85.145"
      height="104.505"
    />
    <rect
      x="349.863"
      y="196.103"
      style="fill:#2CA8FB;"
      width="85.145"
      height="144.491"
    />
    <polyline
      style="fill:#F64B4A;"
      points="298.575,177.047 298.575,139.676 213.426,139.676 213.426,340.598 298.575,340.598 
	298.575,206.206 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
